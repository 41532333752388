<template>
    <div>
        <v-layout wrap row>
            <template v-for="item in praesente">
                <v-flex md2 pa-2>
                    <v-card dark>
                        <v-card-title class="subHeader" primary-title>{{item.name}}</v-card-title>
                        <v-card-text>{{item.beschreibung}}</v-card-text>
                        <v-card-actions>
                        </v-card-actions>
                    </v-card>
                </v-flex>
            </template>
        </v-layout>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    export default {
        data() {
            return {

            }
        },
        components: {

        },
        watch: {

        },
        computed: {
            ...mapState(['profile','praesente'])
        },
        beforeMount() {

        },
        mounted() {
            this.$store.dispatch('getPraesente');
        },
        props: [],
        methods: {
         

        },

    }
</script>

<style scoped>

    .plus:hover { background-color: aliceblue; }
</style>
